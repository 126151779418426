<template>
<div style="height: 100%">
  <div class="mb-5">
    <c-text
        fontSize="2xl"
        fontWeight="700"
    >
      Referrals
    </c-text>
    <c-text>You referred the following businesses to join the Vibrant Creator platform</c-text>
  </div>
  <div v-if="referrals">
    <c-text mb="4">Plan Summary</c-text>
    <c-flex mb="3">
      <data-card class="mr-3" :title="proCounts" :subtitle="'Pro'" />
      <data-card class="mr-3" :title="premiumCounts" :subtitle="'Premium'" />
      <data-card :title="freemiumCounts" :subtitle="'Freemium'" />
    </c-flex>
    <DataTable
        v-if="referrals.length"
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
        stripedRows
        responsiveLayout="stack"
        :paginator="true"
        :rowsPerPageOptions="[10,15,20,50,100]"
        :rows="15"
        :value="referrals"
        class="table-body p-datatable-sm"
    >
      <template #header>
        <div style="display: flex; align-items: center; justify-content: space-between;">
          <span class="datatable-header-title">Referral Details</span>
          <c-input
              v-model="searchQuery"
              placeholder="Search..."
              @input="onSearch"
              style="width: 250px; padding: 8px; margin-left: auto;"
          />
        </div>
      </template>
      <Column field="referred_company.name" header="Name"></Column>
      <Column field="referred_company.country" header="Country"></Column>
      <Column field="Subscription" header="Plan">
        <template #body="slotProps">
          <div>
            {{slotProps.data.Subscription ? (`${Subscription.plan.name} ${Subscription.plan.plan_prices.frequency}`) : 'Freemium'}}
          </div>
        </template>
      </Column>
      <!--      <Column field="company.name" header="Plan"></Column>-->
      <Column field="referred_company.contactEmail" header="Email"></Column>
      <Column header="Actions">
        <template #body="slotProps">
          <c-button style="color: white" size="xs" bg="green.200" @click="sendEmail(slotProps.data.referred_company.contactEmail)">
            Send email
          </c-button>
<!--          <c-button style="color: white" size="xs" bg="tomato" ml="2" @click="sendEmail">-->
<!--            Share-->
<!--          </c-button>-->
        </template>
      </Column>
    </DataTable>

    <c-flex flexDirection="column" v-else justify="center" align="center" w="100%" h="100%">
      <img style="z-index: 55" height="300px" width="300px" src="@/assets/img/empty.svg#empty-state"  alt="empty state"/>
      <c-text fontSize="15px" fontWeight="700" mt="50px">You don't have any referrals yet</c-text>
    </c-flex>
  </div>
  <c-flex h="500px" justify="center" align="center" v-else>
    <c-spinner
        thickness="4px"
        speed="0.65s"
        color="tomato"
        size="xl"
    />
  </c-flex>
</div>
</template>

<script>
import {
  getAffiliateReferralCompanies,
  getFreemiumReferralBusinesses,
  getProOrPremiumReferralBusinesses
} from "@/services/company";
import {mapGetters} from "vuex";
import DataCard from "@/views/Affiliate/components/DataCard.vue";

export default {
  name: "Referrals",
  components: {DataCard},
  data(){
    return {
      searchQuery: '',
      referrals: null,
      premiumCounts: null,
      proCounts: null,
      freemiumCounts: null
    }
  },

  computed: {
    ...mapGetters({
      currentCompany: "company/getActiveCompany"
    })
  },

  methods: {
    onSearch() {
      const query = this.searchQuery.toLowerCase();
      this.filteredCouponData = this.coupons.filter(item => {
        return (
            item.dateUsed.toLowerCase().includes(query) ||
            (item.company && item.company.name.toLowerCase().includes(query)) ||
            item.commissionPaymentStatus.toLowerCase().includes(query) ||
            item.commissionAmount.toString().includes(query)
        );
      });
    },

    allProSubscribers() {
      try {
        getProOrPremiumReferralBusinesses(
            this.currentCompany.id,
            process.env.VUE_APP_PLAN_PRO_MONTHLY,
            process.env.VUE_APP_PLAN_PRO_YEARLY
        )
            .subscribe({
              next: (res) => {
                console.log('GREXILLY', res)
                this.proCounts = res.data.company_referral_aggregate.aggregate.count;
              },
              error: (error) => {
                console.error("Subscription error:", error);
              }
            });
      } catch (e) {
        console.error('Unexpected error:', e);
      }
    },

    allFreemiumSubscribers() {
      try {
        getFreemiumReferralBusinesses(
            this.currentCompany.id
        )
            .subscribe({
              next: (res) => {
                console.log('Hoggerling==>', res)
                this.freemiumCounts = res.data.company_referral_aggregate.aggregate.count;
              },
              error: (error) => {
                console.error("Subscription error:", error);
              }
            });
      } catch (e) {
        console.error('Unexpected error:', e);
      }
    },

    allPremiums(){
      try {
        getProOrPremiumReferralBusinesses(
          this.currentCompany.id,
          process.env.VUE_APP_PLAN_PREMIUM_MONTHLY,
          process.env.VUE_APP_PLAN_PREMIUM_YEARLY
        )
            .subscribe({
              next: (res) => {
                this.premiumCounts = res.data.company_referral_aggregate.aggregate.count
              }
            })
      }catch (e) {
        console.log('Error while fetching data');
      }
    },

    sendEmail(email) {
      // Customize email with a message for existing users, encouraging them to upgrade
      const subject = encodeURIComponent(
        'Unlock the Full Potential of Vibrant Creator with Pro or Premium!'
      );
      const body = encodeURIComponent(
          `Hello,\n\nAs a valued member of the Vibrant Creator community, you have already experienced some of the great tools we offer to support creators. To help you get the most out of Vibrant Creator, consider upgrading to our Pro or Premium plan.\n\nWith these plans, you’ll gain access to exclusive features while leveraging on the AI tools available on this platform.\n\nExplore how upgrading can take your creative journey to new heights!\n\nBest regards,\nThe Vibrant Creator Team`
      );
      window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    },

    async getCompanies(){
      try{
        this.getAffiliatesReferralCompanies()
            .then((response) => {
              if(response.success){
                this.referrals = response.data
                console.log('Data here', this.referrals)
              }
              else{
                this.referrals = []
                console.log('Something went wrong')
              }
            })
      }catch (e){
        console.log('Something went wrong. Please try again.');
      }

    },
    async getAffiliatesReferralCompanies(){
      try {
        const response = await getAffiliateReferralCompanies(this.currentCompany.id, 1000000, 0)
        console.log("logx", response)
        return { success: true, data: response.data.company_referral }
      }catch (e) {
        return { success: false }
      }
    }
  },

  mounted(){
    this.getCompanies();
    this.allPremiums();
    this.allProSubscribers();
    this.allFreemiumSubscribers();
  }
}
</script>

<style lang="scss" scoped>
.table-body{
  font-family: Montserrat
}

</style>